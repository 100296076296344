<!-- @format -->

<template>
    <client-only
        ><LazyMobileModelDrawer
            :title="'activity'"
            :header="false"
            :drawer="drawerDetail"
            :hash="'activity'"
            :opened="openedFn"
            :closed="closedFn"
        >
            <template #body>
                <div class="mobile-sign">
                    <div class="mobile-sign-header">
                        <div class="flex justify-start items-center">
                            <BaseIcon
                                @click="closeFn"
                                name="left"
                                style="font-size: 26px"
                                class="font-bold mr-[16px]"
                            />
                            <base-img class="h-[29px] w-[91px] logo" name="logo" type="png" path="images/logo" />
                        </div>
                    </div>

                    <div class="mobile-sign-content">
                        <div class="w-full flex flex-col gap-[10px]">
                            <el-divider><span class="font-black">Promoções</span> </el-divider>
                            <div
                                class="w-full rounded-[4px] overflow-hidden"
                                v-for="(item, index) in imgList"
                                :key="index"
                            >
                                <base-img class="w-full !block" :name="item" type="jpg" path="images/activity" />
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </LazyMobileModelDrawer>
    </client-only>
</template>

<script setup lang="ts">
    const route = useRoute();
    const drawerDetail = ref(false);
    const imgList = ["h5_huodong_tu1_bg", "h5_huodong_tu2_bg", "h5_huodong_tu3_bg", "h5_huodong_tu4_bg"];
    const openedFn = () => {};
    const closedFn = () => {};
    const closeFn = () => {
        closePopup("activity");
    };

    watchEffect(() => {
        drawerDetail.value = getHashValue(route.hash) === "activity";
    });
</script>

<style lang="scss" scoped>
    .mobile-sign {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        .mobile-sign-header {
            width: 100%;
            height: 60px;
            padding: 0 15px;
            background-color: #25262b;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.5);
            position: relative;
            z-index: 1;
        }

        .mobile-sign-content {
            width: 100%;
            background-color: #17191f;
            height: calc(100% - 60px - env(safe-area-inset-bottom));
            padding-bottom: env(safe-area-inset-bottom);
            overflow-x: hidden;
            position: relative;
            padding: 0 16px;
            box-sizing: border-box;
        }
        ::v-deep(.el-image__inner) {
            height: inherit !important;
        }
        ::v-deep(.el-divider__text) {
            background-color: #17191f;
            padding: 0 8px;
            word-break: break-all;
            width: max-content;
        }
    }
</style>
